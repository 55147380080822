<template>
    <KCourse loader-class="MBcont" course-id="7" title="Детская голова" :items="items">
        <div class="MBtextcont">
            
            <h3>1.1 Лепим заготовки из пластилина</h3>
            <p>Для работы вам понадобится чертеж, <a class="a-download" target ="_blank" href="/img/files/DrawingBEG.jpg">скачать его можно тут</a></p>
        </div>
        <VideoView video-id="a399fb3090784daa861d9ea96e190030"/>

        <div class="MBtextcont">
            <h3>1.2 Обтягиваем пластиком</h3>
        </div>
        <VideoView video-id="da8186d6099c440dab56e7f5eb9a61f6"/>

        <div class="MBtextcont">
            <h3>1.3 Достаем пластилин</h3>
        </div>
        <VideoView video-id="f3b6a6e7780a46a1a3bf18a6ebfeb892"/>


    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
            return {
                items: [
                    { title: "Делаем заготовки", url:"/mycourses/childhead/1"},
                    { title: "Лепим лицо", url:"/mycourses/childhead/2"},
                    { title: "Дорабатываем детали", url:"/mycourses/childhead/3"}
                ],
            }
        }
    }
</script>